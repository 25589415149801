/*-----------------------------------
[Table of Contents]
1. Base
2. animations
3. home header and page header
4. home into
5. portfolios
6. section-title
7. client
8. playground
9. members
10. image and text, icon and text
11. form
12. case study page
13. post
14. footer
15. fullscreen menu

--------------*/

/* base */
html, body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  overflow-x: hidden;
  line-height: 1.7;
}

a {
  color: #000;
}

a:hover {
  color: #000;
}

a:visited, a:focus {
  color: #000;
}

a.text-link {
  font-size: 22px;
  padding: 5px 0;
  letter-spacing: 1px;
  border-bottom: 1px solid #000;
}

a.text-link:hover {
  border-bottom: 2px solid #000;
}

.view-all {
  text-align: center;
  margin-bottom: 30px;
}

.view-services {
  padding-top: 70px;
  text-align: center;
  clear: both;
  margin-bottom: 30px;
}

.button {
  text-transform: uppercase;
  position: relative;
  z-index: 1;
  letter-spacing: 1px;
  line-height: 1.4rem;
  padding: 10px 40px 10px 50px;
  font-size: 18px;
  font-weight: bold;
}

.button.boxed.white {
  border: 1px solid #fff;
  background: none;
  color: #fff;
}

.button.boxed.white:hover {
  color: #333;
}

.button.boxed.grey {
  border: 1px solid #ccc;
  background: none;
  color: #ccc;
}

.button.boxed.grey:hover {
  color: #000;
  background: #ccc;
}

.button.boxed.black {
  border: 2px solid #333;
  background: none;
  color: #333;
}

.button.boxed.black:hover {
  color: #fff;
  background: #333;
}

.centered-text {
  text-align: center;
}

.full {
  clear: both;
  padding: 170px 7vw 140px;
  background-size: cover;
  background-position: 50% 50%;
  position: relative;
  overflow: hidden;
  background: #f5f5f5;

}

.full.no-sides {
  padding: 170px 0 140px;
}

.full.no-bottom {
  padding-bottom: 0;
}

.full.no-top {
  padding-top: 0;
}

.full.no-bg {
  background: transparent;
}

.full.grey {
  background: #eee;
}

.full.light-grey {
  background: #f5f5f5;
}

.full.white {
  background: #fff;
}

.full.dark {
  background: #000;
}

.full.blue {
  background: #020c9c;
  background: #666;
}

.full.full-height {
  height: 100vh;
}

h1 {
  font-size: 70px;
  font-weight: 800;
  font-family: 'Open Sans', sans-serif;
  letter-spacing: -3px;
  line-height: 1.1em;
}

h2 {
  font-size: 58px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  letter-spacing: -2px;
  line-height: 1.15em;
}

h3 {
  font-size: 42px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  letter-spacing: -2px;
  line-height: 1.2em;
}

h4 {
  font-size: 32px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  letter-spacing: -1px;
  line-height: 1.3em;
}

h5 {
  font-size: 26px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 1.4em;
}

p {
  line-height: 1.65;
}

ul, ol, dl {
  line-height: 1.65;
}

#content {
  height: 100%;
  overflow: auto;
}


/* animations */

@keyframes scaleImage {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.03);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes slideText {
  0% {
    transform: translateY(200px);
     opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    transform: translateY(0);
     opacity: 1;
  }
}

@keyframes changeOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes rotateImage{
  0% {
    transform: rotate(-150deg);
  }

  50% {
    transform: rotate(-360deg);
  }

  70% {
    transform: rotate(-150deg);
  }

  100% {
    transform: rotate(-150deg);
  }
}

@keyframes scaleImage-2 {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  70% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
}


/* home header and page header */

#header {

  display: block;
  height: 100vh;

  h1 {
    position: fixed;
    left: 50%;
    color: #fff;
    display: inline-block;
    z-index: -1;
    opacity: 0;
    animation: changeOpacity 0.2s ease forwards;
    animation-delay: 0.9s;
    font-size: 140px;
    letter-spacing: -6px;
    line-height: 1em;
  }

  h2 {
    font-size: 22px;
    letter-spacing: 0;
    color: #fff;
    font-weight: normal;
    position: fixed;
    bottom: 50px;
    right: 50px;
    z-index: -1;
    line-height: 1.5em;
    opacity: 0;
    animation: changeOpacity 0.5s ease forwards;
    animation-delay: 1.2s;
  }

  h2::before {
    display: block;
    content: "";
    width: 1px;
    height: 100px;
    background: #04b36b;
    position: absolute;
    left: -20px;
    top: 0;
  }

  img {
    position: fixed;
    z-index: -2;

  }

  img.fist {
    bottom: -10px;
    display: none;
    opacity: 0;
    animation: slideText 0.5s ease forwards;
    animation-delay: 0.3s;
  }

  img.triangle {
    right: 87%;
    bottom: 120px;
    opacity: 0;
    animation: changeOpacity 0.3s ease forwards;
    animation-delay: 1s;
  }

  img.circle-multiply {
    right: 15%;
    bottom: 200px;
    mix-blend-mode: overlay;
    opacity: 0;
    animation: changeOpacity 0.3s ease forwards;
    animation-delay: 1.1s;
  }

  img.wave {
    right: 14%;
    bottom: 240px;
    opacity: 0;
    animation: changeOpacity 0.3s ease forwards;
    animation-delay: 1.3s;
  }

  img.circle-dissolve {
    right: 25%;
    bottom: 40%;
    z-index: -1;
    opacity: 0;
    animation: changeOpacity 0.3s ease forwards;
    animation-delay: 0.9s;
  }

  svg {
    position: fixed;
    top: 0;
    height: 100vh;
    width: 100%;
    z-index: -3;
  }

  svg path {
    mix-blend-mode: multiply;
  }
}

#header.animated {
  .animo {
    z-index: 2;
    position: absolute;
    transition-property: all;
    transition-duration: .8s;
    transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }

}

.page-header {
  min-height: 100vh;
  height: auto;
  h1 {
    position: relative;
    margin-bottom: 60px;
    color: #000;
    display: inline-block;
    z-index: -1;
    font-size: 140px;
    letter-spacing: -6px;
    line-height: 1em;
  }

  h1.white {
    color: #fff;
  }

  h1.grey {
    color: #f5f5f5;
  }

  p {
    line-height: 1.6;
  }

  p.grey {
    color: #aaa;
  }

  p.grey a {
    color: #aaa;
  }

  p.grey a:hover {
    color: #aaa;
  }

  p.grey a.button {
    margin: 10px 0;
  }

  .contact-details {
    float: right;
  }

  .contact-details p {
    margin-bottom: 15px;
    font-size: 18px;
  }
}

.page-header p {
  font-size: 26px;
  margin-bottom: 30px;
  letter-spacing: 0;
}

.page-header svg {
  position: absolute;
  top: 0;
  height: 100vh;
  width: 100%;
  z-index: -3;

}

.page-header svg path {
  mix-blend-mode: multiply;
}

.page-header img {
  display: block;
  margin: 140px auto 0;
}

.page-header.short-header {
  height: auto;
  min-height: auto;
  padding: 200px 7vw 100px;
  position: relative;
}

.page-header.short-header h1 {
  margin-bottom: 40px;
}

.page-header.short-header img.triangle {
  margin: 0;
  position: absolute;
  right: 15vw;
  bottom: 150px;
  transform: rotate(-150deg);
  animation: rotateImage 5s ease-in-out infinite;
}

.page-header.short-header img.circle-dissolve {
  margin: 0;
  position: absolute;
  right: 20vw;
  bottom: 200px;
  animation: scaleImage-2 4s ease-in-out infinite;
}

/* home into */

.intro-text h2 {
  margin-bottom: 40px;
  font-size: 52px;
}

.intro-text p {
  font-size: 40px;
  line-height: 1.4em;
  margin-bottom: 30px;
  letter-spacing: -1px;
}

/* portfolios */

.work-item {
  margin-bottom: 70px;
  overflow: hidden;
  position: relative;
}

.work-item video {
  width: 100%;
}

.work-item img {
  width: 100%;
}

.work-item:hover img {
  animation: scaleImage 6s ease infinite;
}

.work-item h3 {
  position: absolute;
  z-index: 1;
  font-weight: bold;
  color: #fff;
  white-space: nowrap;
  display: none;
}

.work-item h3 a {
  color: #fff;
}

.work-item h3.dark-title a {
  color: #000;
}


/* section-title */

.section-title {
  text-align: center;
  font-size: 22px;
  margin-bottom: 60px;

}

.section-title h2 {
  margin-bottom: 30px;
}

.section-title.no-bottom {
  margin-bottom: 0;
}

.section-title p {
  line-height: 1.6;
}

/* client */

.clients .columns {
  padding: 0;
}

.client {
  text-align: center;
  padding: 0 0 30px;
}

/* playground */

.playground-left {
  position: relative;
  margin-bottom: 100px;
}

.playground-small {
  left: 20%;
  width: 40%;
  top: 250px;
  position: relative;
}

.playground-right {
  position: relative;
  z-index: 100;
  margin-left: 30%;
}

/* members */
.members .columns {
  padding: 0;
}

.modBoxedTextSlider {
  width: 100%;
  cursor: grab;
}

.modBoxedTextSlider .slidebox {
  position: relative;
}

.big-letter {
  color: #fff;
  opacity: 0.9;
  font-size: 500px;
  line-height: 0.9em;
  font-weight: 800;
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translateY(-65%);
  text-align: center;
  transition: opacity 0.2s ease-in;
}

.modBoxedTextSlider .slidebox img {
  background: #eee;
}

.member-dec {
  padding: 30px 0 0;
  text-align: center;
}

.member-dec h3 {
  margin-bottom: 5px;
  font-size: 24px;
  text-transform: none;
  letter-spacing: -1px;
}

.member-dec p {
  font-size: 13px;
  color: #999;
  text-transform: uppercase;
}

.members .slidebox:hover .big-letter {
  opacity: 0;
}

ul.socials {
  list-style: none;
  margin: 30px 0 0;
}
ul.socials li {
  display: inline-block;
}

ul.socials li a {
  color: #06d982;
  display: inline-block;
  padding: 5px;
  margin-right: 5px;
  font-size: 24px;
}

ul.socials li a:hover {
  color: #ccc;
}

/* image and text, icon and text */

.image-text {
  margin-bottom: 80px;
}

.image-text:last-child {
  margin-bottom: 0;
}

.image-text .columns {
  padding: 0 30px;
}

.image-text h3 {
  font-size: 36px;
  margin-top: 30px;
  margin-bottom: 20px;
  line-height: 1.3em;
}

.alignRight {
  float: right;
}

.icon-text h3 {
  font-size: 28px;
  letter-spacing: -1px;
  margin-bottom: 20px;
}

.icon-text .icon-bg {
  margin-bottom: 40px;
  width: 96px;
  height: 96px;
  display: inline-block;
  background: #24e394;
  border-radius: 48px;
  position: relative;
  left: 12px;
  top: 12px;
}

.icon-text i {
  display: block;
  margin: 0 auto 40px;
  font-size: 48px;
  width: 96px;
  height: 96px;
  line-height: 106px;
  display: inline-block;
  color: #000;
  border-radius: 48px;
  border: 2px solid #000;
  background: none;
  text-align: center;
  position: relative;
  left: -12px;
  top: -12px;
  transform: translateY(0);
  transition: transform 0.2s ease;
}

.icon-text:hover {
  i {
    transform: translateY(-10px);
  }
}

/* form */
input[type="text"], input[type="password"], input[type="date"], input[type="datetime"], input[type="datetime-local"], input[type="month"], input[type="week"], input[type="email"], input[type="number"], input[type="search"], input[type="tel"], input[type="time"], input[type="url"], textarea {
  padding: 15px;
  height: 3rem;
  margin-bottom: 1.875rem;
}

form input[type="text"], form input[type="password"], form input[type="date"], form input[type="datetime"], form input[type="datetime-local"], form input[type="month"], form input[type="week"], form input[type="email"], form input[type="number"], form input[type="search"], form input[type="tel"], form input[type="time"], form input[type="url"], form textarea {
  background: none;
  border: 1px solid #999;
  color: #000;
}

input[type="text"]:focus, input[type="password"]:focus, input[type="date"]:focus, input[type="datetime"]:focus, input[type="datetime-local"]:focus, input[type="month"]:focus, input[type="week"]:focus, input[type="email"]:focus, input[type="number"]:focus, input[type="search"]:focus, input[type="tel"]:focus, input[type="time"]:focus, input[type="url"]:focus, input[type="color"]:focus, textarea:focus {
  background: none;
  border: 1px solid #999;
}

form.dark input[type="text"], form.dark input[type="password"], form.dark input[type="date"], form.dark input[type="datetime"], form.dark input[type="datetime-local"], form.dark input[type="month"], form.dark input[type="week"], form.dark input[type="email"], form.dark input[type="number"], form.dark input[type="search"], form.dark input[type="tel"], form.dark input[type="time"], form.dark input[type="url"], form.dark textarea {
  background: none;
  border: 1px solid #999;
  color: #333;
}

form.dark input[type="text"]:focus, form.dark input[type="password"]:focus, form.dark input[type="date"]:focus, form.dark input[type="datetime"]:focus, form.dark input[type="datetime-local"]:focus, form.dark input[type="month"]:focus, form.dark input[type="week"]:focus, form.dark input[type="email"]:focus, form.dark input[type="number"]:focus, form.dark input[type="search"]:focus, form.dark input[type="tel"]:focus, form.dark input[type="time"]:focus, form.dark input[type="url"]:focus, form.dark input[type="color"]:focus, form.dark textarea:focus {
  background: none;
  border: 1px solid #999;
}

form textarea {
  min-height: 150px;
}

input::placeholder, textarea::placeholder  {
  color: #999;
}

input.button:hover {
  color: #eee;
}

label.error {
  position: relative;
  top: -25px;
  color: red;
}

/* case study page */


.case-intro {
  font-size: 20px;
}
.case-intro .case-title {
  font-size: 100px;
  line-height: 1em;
  font-weight: 700;
  margin-bottom: 40px;
}

.case-intro h3 {
  font-size: 36px;
}

.case-intro .case-text-big {
  font-size: 32px;
  padding-right: 60px;
  line-height: 1.5em;
  letter-spacing: -1px;
}

.case-intro p a.text-link {
  padding-top: 20px;
  display: inline-block;
}

.case-intro .case-tags {
  margin-bottom: 10px;
  font-size: 16px;
}

.case-intro .case-tags strong, .case-intro .case-tags span {
  display: table-cell;
}

.case-intro .case-tags strong {
  padding-right: 10px;
  text-transform: uppercase;
}

.bg-text-honri {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 30%;
  z-index: 2;
  width: 200vw;
  height: auto;
}

.bg-text-honri h2 {
  font-weight: 800;
  font-size: 30vw;
  line-height: 0.8em;
  color: #000;
  opacity: 0.04;
}

.float-img {
  position: absolute;
  bottom: 30%;
  left: 50%;
}

.color-wrapper {
  width: 100%;
  height: 200px;
}

.color-wrapper #color-1 {
  background: #000;
  height: 100%;
  color: #fff;
  padding: 20px;
  box-shadow: 0px 0px 20px 5px #ccc;
}

.color-wrapper #color-2 {
  background: #dc4f84;
  height: 100%;
  color: #fff;
  padding: 20px;
  box-shadow: 0px 0px 20px 5px #ccc;
}

.color-wrapper #color-3 {
  background: #a0b3c0;
  height: 100%;
  color: #fff;
  padding: 20px;
  box-shadow: 0px 0px 20px 5px #e5e5e5;
}

.color-wrapper #color-4 {
  background: #fff;
  height: 100%;
  color: #000;
  padding: 20px;
  box-shadow: 0px 0px 20px 5px #eee;
}

.case-video video {
  width: 100%;
  padding: 0 40px;
}

.case-image img {
  width: 100%;
  padding: 0 40px;
}

/* post */
.post {

  padding: 40px 0;
  border-top: 1px solid #e0e0e0;
}

.post:last-child {
  border-bottom: 1px solid #e0e0e0;
}

.post .row {
  position: relative;
}

.post:hover {
  background: #f9f9f9;
}

.post.featured:hover {
  background: none;
}

.post.featured .post-desc h3 {
  font-size: 46px;
}

.post .post-desc {
  position: relative;
}

.post .post-desc h3 {
  margin-bottom: 10px;
  font-size: 30px;
  letter-spacing: -1px;
}

.post .post-image {
  opacity: 0;
  max-width: 250px;
  position: absolute;
  z-index: 2;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  transition: opacity 0.2s ease-in;
}

.post:hover .post-image {
  opacity: 1;

}

.post .info-others {
  span {
    padding-right: 10px;
    text-transform: uppercase;
    font-size: 13px;
    color: #999;
    font-weight: 700;
  }
}

.post .info-others a {
  color: #999;
}
.post .info-others a:hover {
  color: #000;
}

.single .post {
  border: none;
  padding: 0;
}

.single .post:hover {
  background: transparent;
}

.single .post .post-desc h2 {
  margin-bottom: 20px;
}

.single .post .post-desc p.info-others {
  margin-bottom: 40px;
}

.single .post img {
  margin-bottom: 1rem;
}

.single .post-content h1 {
  font-weight: 700;
}

.single .post-content a {
  text-decoration: underline;
}

.comments-wrapper {
  margin-bottom: 60px;
}

.comments-wrapper ul.comments {
  margin-left: 0;
}

.comments-wrapper ul.comments li {
  border-bottom: 1px solid #e0e0e0;
  padding: 20px 0 0;
  list-style: none;
}

.comments-wrapper ul.comments a:hover {
  text-decoration: underline;
}

.comments-wrapper ul.comments .meta .avatar {
  float: left;
  padding: 5px 10px 0 0;
}

.comments-wrapper ul.comments .meta .name {
  display: block;
}

.comments-wrapper ul.comments .meta .datetime {
  font-size: 0.875rem;
  color: #999;
}

.comments-wrapper ul.comments ul.children li {
  border-bottom: none;
  border-top: 1px solid #e0e0e0;
}

#comments-form {
  margin-top: 15px;
}


nav.wrapper {
  padding: 0 7vw 100px;
  overflow: hidden;
}

nav.wrapper a span.sub-title {
  display: block;
  color: #000;
}

nav.wrapper a {
  white-space: nowrap;
}

nav.wrapper a span.title {
  font-size: 24px;
  font-family: montserrat;
  color: #000;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}

nav.wrapper a.previous {
  float: left;
  display: block;
  width: 100%;
}

nav.wrapper a.next {
  float: right;
  text-align: right;
  display: block;
  width: 100%;
}

nav.wrapper a.previous i {
  position: relative;
  left: -20px;
  float: left;
  display: block;
  top: 10px;
  font-size: 36px;
  line-height: 36px;
  color: #000;
  transition: left 0.2s ease;
}

nav.wrapper a.next i {
  position: relative;
  right: -20px;
  float: right;
  display: block;
  top: 10px;
  font-size: 36px;
  line-height: 36px;
  color: #000;
  transition: right 0.2s ease;
}

nav.wrapper a.previous:hover i {
  left: -25px;
}

nav.wrapper a.next:hover i {
  right: -25px;
}


.posts.archive .post {
  padding: 40px 40px 20px;
}

.posts.archive .post-image {
  display: none;
}

.posts.archive .post:hover {
  background: none;
}

.posts.archive .post:hover {
  color: #af9568;
  background: none;
}

.posts.archive .post:hover .info-author {
  color: #af9568;
}

.posts.archive .post:hover a {
  color: #af9568;
}

.posts.tag-archive .post {
  padding: 40px 40px 20px;
}

.posts.tag-archive .post-image {
  display: none;
}

.posts.tag-archive .post:hover {
  background: none;
}

.posts.tag-archive .post:hover {
  color: #af9568;
  background: none;
}

.posts.tag-archive .post:hover .info-author {
  color: #af9568;
}

.posts.tag-archive .post:hover a {
  color: #af9568;
}

.posts.category-archive .post {
  padding: 40px 40px 20px;
}

.posts.category-archive .post-image {
  display: none;
}

.posts.category-archive .post:hover {
  background: none;
}

.posts.category-archive .post:hover {
  color: #af9568;
  background: none;
}

.posts.category-archive .post:hover .info-author {
  color: #af9568;
}

.posts.category-archive .post:hover a {
  color: #af9568;
}

/* footer */

.new-footer {
  min-height: 400px;
  cursor: pointer;

  svg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.new-footer h1 {
  white-space: nowrap;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  z-index: 2;
  font-size: 100px;
  cursor: default;
  width: 100%;
  height: 400px;
  text-align: center;
}

.new-footer h1 a {
  cursor: pointer;
  display: block;
  width: 100%;
  height: 100%;
  padding: 150px 0  0;
}




/* fullscreen menu */
// for fullscreen menu
html.oneh, body.oneh {
  overflow: hidden;

  #menu {
    overflow: auto;
  }
}

#menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #fff;
  z-index: 9999;
  visibility: hidden;
  height: 0%;
  opacity: 0;
  transition: opacity 0.2s ease-in;

  #menu {
    height: 100%;
    position: relative;

    ul {
      margin: 0 auto;
      display: block;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -55%);
      list-style: none;
      li {
        opacity: 0;
        transform: translateX(-50px);
        transition: opacity 0.3s ease-in, transform 0.4s ease-in;
      }
      a {
        color: #222;
        font-weight: bold;
        font-size: 55px;
        letter-spacing: 2px;
        line-height: 1.4em;
        text-transform: uppercase;
      }
    }
  }
}

#menu-overlay.open {
  opacity: 1;
  visibility: visible;
  height: 100%;
  #menu ul li {
    opacity: 1;
    transform: translateX(0);
  }
}

#topbar {
  position: fixed;
  height: 60px;
  width: 100%;
  z-index: 99999;

  #logo {
    display: block;
    position: absolute;
    top: 5px;
    left: 30px;
    h1 {
      font-size: 36px;
      color: #000;
      margin: 0;
      padding: 0;
      line-height: 100%;
      font-weight: 700;
      letter-spacing: -5px;
    }
  }

  #menu-toggler {
    display: block;
    cursor: pointer;
    width: 35px;
    height: 27px;
    position: absolute;
    top: 25px;
    right: 40px;
    z-index: 9999;

    span {
      background: #000;
      border: none;
      height: 2px;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      cursor: pointer;
      transition: all .25s ease;
    }

    span.middle {
      // 5 + 6 = 11
      top: 11px;
    }
    span.bottom {
      // 5 + 6 + 5 + 6 = 22
      top: 22px;
    }
  }

  #menu-toggler.active {
    .top {
      // move down 11px, then rotate
      transform: translateY(11px) translateX(0) rotate(45deg);
    }

    .middle {
      opacity: 0;
    }

    .bottom {
      // move up 11px, then rotate
      transform: translateY(-11px) translateX(0) rotate(-45deg);
    }
  }


}